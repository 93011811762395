
import { computed, ref } from 'vue'
import moment from 'moment';
import {FilterMatchMode, FilterOperator} from "primevue/api";
import router from "@/router";
import {useToast} from "primevue/usetoast";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {QuyetDinh} from "@/models/quyetDinh";
import {QuanHuyen} from "@/models/quanHuyen";
import {Dat} from "@/models/dat";
import {NhaO} from "@/models/nhaO";
import {KienTruc} from "@/models/kienTruc";
import {CayCoi} from "@/models/cayCoi";
import {DenBuChinhSach} from "@/models/denBuChinhSach";
import {TroCap} from "@/models/troCap";
import giaiToaRepository from "@/services/GiaiToaRepository";
import {HangMucDat} from "@/models/hangMucDat";
import {HangMucNhaO} from "@/models/hangMucNhaO";
import {HangMucChuan} from "@/models/hangMucChuan";
import {ChiTietNhaO} from "@/models/chiTietNhaO";
import {ChiTietDuAn} from "@/models/chiTietDuAn";
import {Phuong} from "@/models/phuong";
import {ThongTinDatTheoMa} from "@/models/thongTinDatTheoMa";
import {ViTriDat} from "@/models/viTriDat";
import {ViTri} from "@/models/viTri";
import {ViTriKhoangCach} from "@/models/viTriKhoangCach";
import {HangMucKienTruc} from "@/models/hangMucKienTruc";
import {HangMucChinhSach} from "@/models/hangMucChinhSach";
import {HangMucTroCap} from "@/models/hangMucTroCap";
import {HangMucCayCoi} from "@/models/hangMucCayCoi";
import {ItemChinh} from "@/models/itemChinh";
import {ItemChiTiet} from "@/models/itemChiTiet";
export default {
  setup(){
    const toast = useToast();
    const store = useStore();
    const date = ref();
    const service = ref("");
    const router = useRouter();
    const qDinh = ref(0);
    const quanHuyen = ref(0);
    const dsQuyetDinh = ref([] as QuyetDinh[]);
    const dsQuan = ref([] as QuanHuyen[]);
    const dsPhuong = ref([] as Phuong[]);
    const dsDat = ref([] as Dat[]);
    const dsNhaO = ref([] as NhaO[]);
    const dsKienTruc = ref([] as KienTruc[]);
    const hangKienTruc = ref({} as HangMucKienTruc);
    const dsHangMucKienTruc = ref([] as HangMucKienTruc[]);
    const kienTruc = ref({} as KienTruc);
    const dsCayCoi = ref([] as CayCoi[]);
    const dsChinhSach = ref([] as DenBuChinhSach[]);
    const hangMucChinhSach = ref({} as HangMucChinhSach);
    const dsHangMucChinhSach = ref([] as HangMucChinhSach[]);
    const dsTroCap = ref([] as TroCap[]);
    const hangMucTroCap = ref({} as HangMucTroCap);
    const dsHangMucTroCap = ref([] as HangMucTroCap[]);
    const hangMucDat = ref({} as HangMucDat);
    const dsHangMucDat = ref([] as HangMucDat[]);
    const itemsNhaO = ref([] as HangMucNhaO[]);
    const nhaO = ref({} as HangMucNhaO);
    const hangMucChuanNhaO = ref({} as HangMucChuan);
    const dsChiTietNhaO = ref([] as ChiTietNhaO[]);
    const chiTietDuAn = ref({} as ChiTietDuAn);
    const thongTinDat = ref({} as ThongTinDatTheoMa);
    const dsViTriDat = ref([] as ViTriDat[]);
    const dsViTriKhoangCach = ref([] as ViTriKhoangCach[]);
    const dsViTriKhac = ref([] as ViTri[]);
    const tongTienDat = ref(0);
    const tongTienKienTruc = ref(0);
    const tongTienChinhSach = ref(0);
    const tongTienNhaO = ref(0);
    const tongTienTroCap = ref(0);
    const tongTienCayCoi = ref(0);
    const tongTien = ref(0);
    const showNhaO = ref(false);
    const showKienTruc = ref(false);
    const showChinhSach = ref(false);
    const showTroCap = ref(false);
    const showCayCoi = ref(false);
    const checkValidDat= ref();
    const hangMucCayCoi = ref({} as HangMucCayCoi);
    const dsHangMucCayCoi = ref([] as HangMucCayCoi[]);
    const itemChinhs = ref([] as ItemChiTiet[]);
    const itemDenBuDats = ref([] as ItemChiTiet[]);
    const itemNhaOs = ref([] as ItemChiTiet[]);
    const itemKienTrucs = ref([] as ItemChiTiet[]);
    const itemChinhSachs = ref([] as ItemChiTiet[]);
    const itemTroCaps = ref([] as ItemChiTiet[]);
    const itemHoaMaus = ref([] as ItemChiTiet[]);


    // const toObject = (value) => {
    //   return JSON.parse(JSON.stringify(this, (key, value) =>
    //       typeof value === 'Number'
    //           ? value.toString()
    //           : value // return everything else unchanged
    //   ));
    // }
    hangMucDat.value.thuHoi = false;
    const initData = () => {
      itemChinhs.value = [];
      itemDenBuDats.value.forEach(x => {x.tenNhom = 'Đất'; x.soNhom = 1});
      itemNhaOs.value.forEach(x => {x.tenNhom = 'Nhà ở'; x.soNhom = 2});
      itemKienTrucs.value.forEach(x => {x.tenNhom = 'Kiến trúc'; x.soNhom = 3});
      itemChinhSachs.value.forEach(x => {x.tenNhom = 'Chính sách'; x.soNhom = 4});
      itemTroCaps.value.forEach(x => {x.tenNhom = 'Trợ cấp'; x.soNhom = 5});
      itemHoaMaus.value.forEach(x => {x.tenNhom = 'Cây cối hoa màu'; x.soNhom = 6});
      if(itemDenBuDats.value.length < 1){
        itemChinhs.value.push(({tt: 1, tenNhom : 'Đất', soNhom : 1, tongTien: Number(0)}))
      }
      else {
        itemDenBuDats.value.forEach(x => itemChinhs.value.push(x));
      }

      if(itemNhaOs.value.length < 1){
        itemChinhs.value.push(({tt: 1, tenNhom : 'Nhà ở', soNhom : 2, tongTien: Number(0)}))
      }
      else {
        itemNhaOs.value.forEach(x => itemChinhs.value.push(x));
      }

      if(itemKienTrucs.value.length < 1){
        itemChinhs.value.push(({tt: 1, tenNhom : 'Kiến trúc', soNhom : 3, tongTien: Number(0)}))
      }
      else {
        itemKienTrucs.value.forEach(x => itemChinhs.value.push(x));
      }

      if(itemChinhSachs.value.length < 1){
        itemChinhs.value.push(({tt: 1, tenNhom : 'Chính sách', soNhom : 4, tongTien: Number(0)}))
      }
      else {
        itemChinhSachs.value.forEach(x => itemChinhs.value.push(x));
      }

      if(itemTroCaps.value.length < 1){
        itemChinhs.value.push(({tt: 1, tenNhom : 'Trợ cấp', soNhom : 5, tongTien: Number(0)}))
      }
      else {
        itemTroCaps.value.forEach(x => itemChinhs.value.push(x));
      }

      if(itemHoaMaus.value.length < 1){
        itemChinhs.value.push(({tt: 1, tenNhom : 'Cây cối hoa màu', soNhom : 6, tongTien: Number(0)}))
      }
      else
      {
        itemHoaMaus.value.forEach(x => itemChinhs.value.push(x));
      }
    }

    // console.log("################################ itemChinhs: " + JSON.stringify(itemChinhs, (_, v) => typeof v === 'Number' ? v.toString() : v));
    // console.log("################################ itemChinhs: " + toObject(itemChinhs.value));

    initData();

    giaiToaRepository.getDmQuyetDinh()
        .then((response) => {
          dsQuyetDinh.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:err.response.data,
            life: 4000
          });
        });

    giaiToaRepository.getDsQuan()
        .then((response) => {
          dsQuan.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:err.response.data,
            life: 4000
          });
        });

      giaiToaRepository.getDmDat()
        .then((response) => {
          dsDat.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:err.response.data,
            life: 4000
          });
        });

    const quyetDinhSelect = () => {
      dsNhaO.value = [];
      dsKienTruc.value = [];
      dsCayCoi.value = [];
      dsChinhSach.value = [];
      dsTroCap.value = [];
      dsChiTietNhaO.value = [];
      qDinh.value = chiTietDuAn.value.so_QD;
      giaiToaRepository.getHangMucNhaO(qDinh.value)
          .then((response) => {
            dsNhaO.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 4000
            });
          });
      giaiToaRepository.getKienTruc(qDinh.value)
          .then((response) => {
            dsKienTruc.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 4000
            });
          });
      giaiToaRepository.getCayCoi(qDinh.value)
          .then((response) => {
            dsCayCoi.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 4000
            });
          });
      giaiToaRepository.getDenBuChinhSach(qDinh.value)
          .then((response) => {
            dsChinhSach.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 4000
            });
          });
      giaiToaRepository.getTroCap(qDinh.value)
          .then((response) => {
            dsTroCap.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 4000
            });
          });
      giaiToaRepository.getHangMucNhaO(qDinh.value)
          .then((response) => {
            dsChiTietNhaO.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 4000
            });
          });
    }

    const formatCurrency = (value) => {
      if(value == null || value == 0) return '';
      // Use Intl.NumberFormat to format the number as currency
      const formatter = new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
        minimumFractionDigits: 0, // Set the number of decimal places you want
      });
      return formatter.format(value);
    };

    const selectDistrict = () => {
      dsPhuong.value = [];
      giaiToaRepository.getDsPhuong(chiTietDuAn.value.quan)
          .then((response) => {
            dsPhuong.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 4000
            });
          });
    }

    const selectLoaiDat = () => {
      thongTinDat.value.ma_dat = hangMucDat.value.ma;
      dsDat.value.forEach(x => {
        if (x.ma_dat == hangMucDat.value.ma) hangMucDat.value.ten = x.ten_loai_dat
      })
      thongTinDat.value.ma_quyet_dinh = chiTietDuAn.value.so_QD;

      giaiToaRepository.getThongTinDat(thongTinDat.value)
          .then((response) => {
            dsViTriDat.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 4000
            });
          });
      giaiToaRepository.getViTriKhoangCachDat(thongTinDat.value)
          .then((response) => {
            dsViTriKhoangCach.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 4000
            });
          });

      giaiToaRepository.getViTriKhac(thongTinDat.value)
          .then((response) => {
            dsViTriKhac.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 4000
            });
          });
    }
    const selectViTriDat = () => {
      dsViTriDat.value.forEach(x => {if(x.ten_vi_tri == hangMucDat.value.ttvt) hangMucDat.value.gtvt = x.gia_den_bu});
    }

    const selectViTriKhoangCachDat = () => {
      dsViTriKhoangCach.value.forEach(x => {if(x.ten == hangMucDat.value.vtkc) hangMucDat.value.gtkc = x.gia_den_bu});
    }

    const selectViTriKhac = () => {
      dsViTriKhac.value.forEach(x => {if(x.ten_he_so == hangMucDat.value.vtk) hangMucDat.value.gtk = x.gia_den_bu});
    }

    const themDat = () => {
      //hangMucDat.value.ttvt || hangMucDat.value.vtkc || hangMucDat.value.vtk
      if(hangMucDat.value.sd == null) hangMucDat.value.sd = 100;
      if(hangMucDat.value.khac == null) hangMucDat.value.khac = 100;
      if(hangMucDat.value.gtvt == null) hangMucDat.value.gtvt = 1;
      if(hangMucDat.value.gtkc == null) hangMucDat.value.gtkc = 1;
      if(hangMucDat.value.gtk == null) hangMucDat.value.gtk = 1;

      tongTienDat.value = 0;
      hangMucDat.value.tongTien = hangMucDat.value.gtvt * hangMucDat.value.gtkc * (hangMucDat.value.sd/100)* (hangMucDat.value.khac/100) * hangMucDat.value.sl;
      console.log("@@@@@@@@@@@@@@############### them dat: " + JSON.stringify(hangMucDat.value));
      if(dsHangMucDat.value.length < 1){
        hangMucDat.value.tt = 1;
      }
      else{
        const count = ref(0);
        dsHangMucDat.value.forEach(x => {
          if(x.tt > count.value) count.value = x.tt;
        })
        hangMucDat.value.tt = count.value + 1;
      }
      dsHangMucDat.value.push(hangMucDat.value);
      dsHangMucDat.value.filter(x => {
        tongTienDat.value = tongTienDat.value + x.tongTien;
      })
      console.log("@@@@@@@@@@@@@@############### them dat tongTienDat:  " + JSON.stringify(tongTienDat.value));
      const hangMuc = ref({} as HangMucDat);
      hangMucDat.value = hangMuc.value;
      hangMucDat.value.thuHoi = false;
      checkValidDat.value = false;
      showAddDat.value = false;
      itemDenBuDats.value = [];
      const index = ref(0);
      dsHangMucDat.value.forEach(x => {
        if(x.tt > index.value) index.value = x.tt;
        const itemTemp = ref({} as ItemChiTiet);
        itemTemp.value.tt = x.tt;
        itemTemp.value.ma = x.ma.toString();
        itemTemp.value.ten = x.ten;
        itemTemp.value.sl = x.sl;
        itemTemp.value.khac = x.khac;
        itemTemp.value.sd = x.sd;
        itemTemp.value.tongTien = Number(x.tongTien);
        itemTemp.value.dvt = "đ/m2";

        // console.log("@@@@@@@@@@@@@@############### them dat itemTemp.value:  " + JSON.stringify(itemTemp.value));
        itemDenBuDats.value.push((
          itemTemp.value
        ));
        // console.log("@@@@@@@@@@@@@@############### them dat itemDenBuDats.value:  " + JSON.stringify(itemDenBuDats.value));
      })
      itemDenBuDats.value.push(({tt: index.value + 1, tongTien: Number(0)}))

      initData();
    }
    // const themNhaO = () => {
    //
    // }

    const tinhTongHop = computed(() =>{
      tongTienDat.value = 0;
      dsHangMucDat.value.filter(x => {
        tongTienDat.value = tongTienDat.value + x.tongTien;
      })
      tongTienNhaO.value = 0;
      itemsNhaO.value.filter(x => {
        tongTienNhaO.value = tongTienNhaO.value + x.tong_tien;
      })
      tongTienKienTruc.value = 0;
      dsHangMucKienTruc.value.filter(x => {
        tongTienKienTruc.value = tongTienKienTruc.value + x.tongTien;
      })
      chiTietDuAn.value.dien_tich_thu_hoi = 0;
      dsHangMucDat.value.filter(x => {
        if(!x.thuHoi) { chiTietDuAn.value.dien_tich_thu_hoi = chiTietDuAn.value.dien_tich_thu_hoi + x.sl};
      })
      tongTienChinhSach.value = 0;
      dsHangMucChinhSach.value.filter(x => {
        tongTienChinhSach.value = tongTienChinhSach.value + x.tongTien;
      })
      tongTienTroCap.value = 0;
      dsHangMucTroCap.value.filter(x => {
        tongTienTroCap.value = tongTienTroCap.value + x.tongTien;
      })

      tongTienCayCoi.value = 0;
      dsHangMucCayCoi.value.filter(x => {
        tongTienCayCoi.value = tongTienCayCoi.value + x.tong_tien;
      })
      ////
      tongTien.value = tongTienDat.value +  tongTienNhaO.value + tongTienKienTruc.value + tongTienChinhSach.value + tongTienTroCap.value + tongTienCayCoi.value;
    })


    const updateThuHoi = (id, thuHoi) => {
      dsHangMucDat.value.filter(x => {
        if(x.tt == id) { x.thuHoi = thuHoi};
      })
    }

    const deleteDat = (id) => {
        // console.log("################################ deleteThuoc id: " + id);
      dsHangMucDat.value.splice(dsHangMucDat.value.findIndex(x => x.tt == id), 1);
      dsHangMucDat.value.forEach(x => {
          if(x.tt > id)
          {
            x.tt = x.tt - 1;
          }
        });
      itemDenBuDats.value.splice(itemDenBuDats.value.findIndex(x => x.tt == id), 1);
      itemDenBuDats.value.forEach(x => {
        if(x.tt > id)
        {
          x.tt = x.tt - 1;
        }
      });
      itemChinhs.value = [];
      initData();
    }

    const validDat = ($event) => {
      hangMucDat.value.sl = $event.value;
      checkValidDat.value =  hangMucDat.value.ten && (hangMucDat.value.gtvt || hangMucDat.value.gtk || hangMucDat.value.gtkc) && hangMucDat.value.sl;
    }

    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    const onRowSelect = ($event) => {
      console.log("##################### DEBUG ROW SELECT AA: " );
      console.log("##################### DEBUG ROW SELECT: " + $event.data.ten_hang_muc);
      nhaO.value.ten_no = $event.data.ten_hang_muc;
      nhaO.value.ma_no = $event.data.ma_nha_o;
      nhaO.value.mindenbu_no = $event.data.min_den_bu;
      nhaO.value.maxdenbu_no = $event.data.max_den_bu;
      nhaO.value.dongia_no = $event.data.don_gia_den_bu;
      nhaO.value.cotmocchenhlech_no = $event.data.cot_moc_chenh_lech;
      nhaO.value.dongiachenhlech_no = $event.data.don_gia_chenh_lech;
      nhaO.value.dvt_no = $event.data.don_vi_tinh;
      nhaO.value.ghichu_no = $event.data.ghi_chu;
      nhaO.value.thuhoi = $event.data.tinh_thu_hoi;
      clearFilter();
      showNhaO.value = false;
    }

    const themNhaO = () => {
      if((nhaO.value.dongiachenhlech_no != 0)&&(nhaO.value.chieucao_no == null))
      {
        toast.add({
          severity: 'error',
          summary: 'Lỗi',
          detail:" Vui lòng cung cấp thông tin CHIỀU CAO",
          life: 4000
        });
      }
      else {
        if (itemsNhaO.value.length < 1) {
          nhaO.value.tt = 1;
        } else {
          const count = ref(0);
          itemsNhaO.value.forEach(x => {
            if (x.tt > count.value) count.value = x.tt;
          })
          nhaO.value.tt = count.value + 1;
        }
        if (nhaO.value.sudung_no == null) nhaO.value.sudung_no = 100;
        if (nhaO.value.khac_no == null) nhaO.value.khac_no = 100;
        if (nhaO.value.dongiachenhlech_no != 0) {
          console.log("@@@@@@@@@@@@@@############### them itemNhaOs aaa: ");
          console.log("@@@@@@@@@@@@@@############### them itemNhaOs aaa nhaO.value: " + JSON.stringify(nhaO.value));
          //@@@@@@@@@@@@@@############### them itemNhaOs 11111 x.nhaO: {"ten_no":"Nhà trệt, móng đá hộc, mái ngói, tường xây 110 kể cả mặt trước 220, nền xi măng, chiều cao nhà 3,3m",
          // "ma_no":"B001","mindenbu_no":1233000,"maxdenbu_no":2936000,"dongia_no":2470000,"cotmocchenhlech_no":3.3,"dongiachenhlech_no":57000,"dvt_no":"đ/m2",
          // "ghichu_no":"1.1","thuhoi":true,"chieucao_no":23,"soluong_no":4,"tt":2,"sudung_no":100,"tong_tien":null}
          nhaO.value.tong_tien = nhaO.value.dongia_no * (nhaO.value.sudung_no / 100) * (nhaO.value.khac_no / 100) * nhaO.value.soluong_no
              + nhaO.value.dongiachenhlech_no * (nhaO.value.chieucao_no - nhaO.value.cotmocchenhlech_no) * 10;
          console.log("@@@@@@@@@@@@@@############### them itemNhaOs aaaccc: " + (nhaO.value.dongia_no * (nhaO.value.sudung_no / 100) * (nhaO.value.khac_no / 100) * nhaO.value.soluong_no
              + nhaO.value.dongiachenhlech_no * (nhaO.value.chieucao_no - nhaO.value.cotmocchenhlech_no) * 10));
        } else {
          nhaO.value.tong_tien = nhaO.value.dongia_no * (nhaO.value.sudung_no / 100) * (nhaO.value.khac_no / 100) * nhaO.value.soluong_no;
        }

        if ((nhaO.value.tong_tien > nhaO.value.maxdenbu_no) && (nhaO.value.maxdenbu_no > 0)) {
          nhaO.value.tong_tien = nhaO.value.maxdenbu_no;
        }

        if ((nhaO.value.tong_tien < nhaO.value.mindenbu_no) && (nhaO.value.mindenbu_no > 0)) {
          nhaO.value.tong_tien = nhaO.value.mindenbu_no;
        }
        console.log("@@@@@@@@@@@@@@############### them itemNhaOs 11111 x.nhaO: " + JSON.stringify(nhaO.value));

        itemsNhaO.value.push(nhaO.value);
        itemsNhaO.value.filter(x => {
          tongTienNhaO.value = tongTienNhaO.value + x.tong_tien;
        })
        var nhaOTemp = ref({} as HangMucNhaO);
        nhaO.value = nhaOTemp.value;
        showAddNhaO.value = false;
        itemNhaOs.value = [];
        const index = ref(0);
        itemsNhaO.value.forEach(x => {
          if (x.tt > index.value) index.value = x.tt
          itemNhaOs.value.push(({
            tt: x.tt,
            ma: x.ma_no,
            ten: x.ten_no,
            sl: x.soluong_no,
            khac: x.khac_no,
            sd: x.sudung_no,
            tongTien: Number(x.tong_tien),
            dvt: x.dvt_no,
            dg: x.dongia_no
          }));
          console.log("@@@@@@@@@@@@@@############### them itemNhaOs 11111 x.tong_tien: " + x.tong_tien);
          console.log("@@@@@@@@@@@@@@############### them itemNhaOs 11111 x.tong_tien: " + Number(x.tong_tien));
          console.log("@@@@@@@@@@@@@@############### them itemNhaOs 11111: " + JSON.stringify(itemNhaOs.value));
        })
        itemNhaOs.value.push(({tt: index.value + 1, tongTien: Number(0)}))
        console.log("@@@@@@@@@@@@@@############### them itemNhaOs 2222: " + JSON.stringify(itemNhaOs.value));
        initData();
      }
    }

    const deleteNhaO = (id) => {
      // console.log("################################ deleteThuoc id: " + id);
      itemsNhaO.value.splice(itemsNhaO.value.findIndex(x => x.tt == id), 1);
      itemsNhaO.value.forEach(x => {
        if(x.tt > id)
        {
          x.tt = x.tt - 1;
        }
      });
      itemNhaOs.value.splice(itemNhaOs.value.findIndex(x => x.tt == id), 1);
      itemNhaOs.value.forEach(x => {
        if(x.tt > id)
        {
          x.tt = x.tt - 1;
        }
      });
      initData();
    }

    const deleteItem = (id, kind) => {
      if(kind == 1){
        deleteDat(id);
      };
      if(kind == 2){
        deleteNhaO(id);
      };
      if(kind == 3){
        deleteKienTruc(id);
      }
      if(kind == 4){
        deleteChinhSach(id);
      }
      if(kind == 5){
        deleteTroCap(id);
      }
      if(kind == 6){
        deleteCayCoi(id);
      }
    }

    const selectNhaO = () => {
      if(checkQuyetDinh())
      showNhaO.value = true;
    }

    const onRowSelectKT = ($event) => {
      hangKienTruc.value.ten_kt = $event.data.ten_vat_kien_truc;
      hangKienTruc.value.ma_kt = $event.data.ma_vat_kien_truc;
      hangKienTruc.value.gia_kt = $event.data.gia_den_bu;
      hangKienTruc.value.dvt_kt = $event.data.don_vi_tinh;
      hangKienTruc.value.thuhoi = $event.data.tinh_thu_hoi;
      clearFilter();
      showKienTruc.value = false;
    }

    const themKienTruc = () => {
      if(dsHangMucKienTruc.value.length < 1){
        hangKienTruc.value.tt = 1;
      }
      else{
        const count = ref(0);
        dsHangMucKienTruc.value.forEach(x => {
          if(x.tt > count.value) count.value = x.tt;
        })
        hangKienTruc.value.tt = count.value + 1;
      }
      if(hangKienTruc.value.khac_kt == null) hangKienTruc.value.khac_kt = 100;
      if(hangKienTruc.value.sd_kt == null) hangKienTruc.value.sd_kt = 100;

      hangKienTruc.value.tongTien = hangKienTruc.value.sl_kt * hangKienTruc.value.gia_kt * (hangKienTruc.value.sd_kt/100) * (hangKienTruc.value.khac_kt/100);

      dsHangMucKienTruc.value.push(hangKienTruc.value);
      dsHangMucKienTruc.value.filter(x => {
        tongTienKienTruc.value = tongTienKienTruc.value + x.tongTien;
      })
      var ktTemp = ref({} as HangMucKienTruc);
      hangKienTruc.value = ktTemp.value;

      showAddKienTruc.value = false;
      itemKienTrucs.value = [];
      const index = ref(0);
      dsHangMucKienTruc.value.forEach(x => {
        if(x.tt > index.value) index.value = x.tt
        itemKienTrucs.value.push(({
          tt: x.tt, ma: x.ma_kt, ten: x.ten_kt, sl: x.sl_kt, khac: x.khac_kt, sd: x.sd_kt, tongTien: Number(x.tongTien), dvt: x.dvt_kt, dg: x.gia_kt
        }));
      })
      itemKienTrucs.value.push(({tt: index.value + 1, tongTien: Number(0)}))
      initData();
    }

    const deleteKienTruc = (id) => {
      // console.log("################################ deleteThuoc id: " + id);
      dsHangMucKienTruc.value.splice(dsHangMucKienTruc.value.findIndex(x => x.tt == id), 1);
      dsHangMucKienTruc.value.forEach(x => {
        if(x.tt > id)
        {
          x.tt = x.tt - 1;
        }
      });
      itemKienTrucs.value.splice(itemKienTrucs.value.findIndex(x => x.tt == id), 1);
      itemKienTrucs.value.forEach(x => {
        if(x.tt > id)
        {
          x.tt = x.tt - 1;
        }
      });
      initData();
    }

    const selectKienTruc = () => {
      if(checkQuyetDinh())
      showKienTruc.value = true;
    }

    const checkQuyetDinh = () => {
      console.log("################################ checkQuyetDinh: ");
      if(chiTietDuAn.value.so_QD == null){
        console.log("################################ checkQuyetDinh 111111111: ");
        toast.add({
          severity: 'error',
          summary: 'Lỗi',
          detail:'Quyết định chưa được chọn, vui lòng chọn quyết định sau đó thực hiện lại.',
          life: 4000
        });
        return false;
      }
      else{
        console.log("################################ checkQuyetDinh 2222222: ");
        return true;
      }
    }

    const onRowSelectCS = ($event) => {
      hangMucChinhSach.value.ten_cs = $event.data.ten_chinh_sach;
      hangMucChinhSach.value.ma_cs = $event.data.ma_chinh_sach;
      hangMucChinhSach.value.gia_cs = $event.data.gia_den_bu;
      hangMucChinhSach.value.dvt_cs = $event.data.don_vi_tinh;
      clearFilter();
      showChinhSach.value = false;
    }

    const themChinhSach = () => {
      if(dsHangMucChinhSach.value.length < 1){
        hangMucChinhSach.value.tt = 1;
      }
      else{
        const count = ref(0);
        dsHangMucChinhSach.value.forEach(x => {
          if(x.tt > count.value) count.value = x.tt;
        })
        hangMucChinhSach.value.tt = count.value + 1;
      }
      if(hangMucChinhSach.value.khac_cs == null) hangMucChinhSach.value.khac_cs = 100;
      if(hangMucChinhSach.value.sd_cs == null) hangMucChinhSach.value.sd_cs = 100;

      hangMucChinhSach.value.tongTien = hangMucChinhSach.value.sl_cs * hangMucChinhSach.value.gia_cs * (hangMucChinhSach.value.sd_cs/100) * (hangMucChinhSach.value.khac_cs/100);

      dsHangMucChinhSach.value.push(hangMucChinhSach.value);
      dsHangMucChinhSach.value.filter(x => {
        tongTienChinhSach.value = tongTienChinhSach.value + x.tongTien;
      })
      var ktTemp = ref({} as HangMucChinhSach);
      hangMucChinhSach.value = ktTemp.value;
      showAddChinhSach.value = false;
      itemChinhSachs.value = [];
      const index = ref(0);
      dsHangMucChinhSach.value.forEach(x => {
        if(x.tt > index.value) index.value = x.tt
        itemChinhSachs.value.push(({
          tt: x.tt, ma: x.ma_cs, ten: x.ten_cs, sl: x.sl_cs, khac: x.khac_cs, sd: x.sd_cs, tongTien: Number(x.tongTien), dvt: x.dvt_cs, dg: x.gia_cs
        }));
      })
      itemChinhSachs.value.push(({tt: index.value + 1, tongTien: Number(0)}))
      initData();
    }

    const deleteChinhSach = (id) => {
      // console.log("################################ deleteThuoc id: " + id);
      dsHangMucChinhSach.value.splice(dsHangMucChinhSach.value.findIndex(x => x.tt == id), 1);
      dsHangMucChinhSach.value.forEach(x => {
        if(x.tt > id)
        {
          x.tt = x.tt - 1;
        }
      });
      itemChinhSachs.value.splice(itemChinhSachs.value.findIndex(x => x.tt == id), 1);
      itemChinhSachs.value.forEach(x => {
        if(x.tt > id)
        {
          x.tt = x.tt - 1;
        }
      });
      initData();
    }

    const selectChinhSach = () => {
      if(checkQuyetDinh())
        showChinhSach.value = true;
    }

    const onRowSelectTC = ($event) => {
      hangMucTroCap.value.ten_tc = $event.data.ten_tro_cap;
      hangMucTroCap.value.ma_tc = $event.data.ma_tro_cap;
      hangMucTroCap.value.gia_tc = $event.data.gia_den_bu;
      hangMucTroCap.value.dvt_tc = $event.data.don_vi_tinh;
      hangMucTroCap.value.gia_cao_nhat_tc = $event.data.max_den_bu;
      hangMucTroCap.value.gia_thap_nhat_tc = $event.data.min_den_bu;
      hangMucTroCap.value.tinh_theo_nha_o = $event.data.tinh_theo_nha_o;
      clearFilter();
      showTroCap.value = false;
    }

    const themTroCap = () => {
      if(dsHangMucTroCap.value.length < 1){
        hangMucTroCap.value.tt = 1;
      }
      else{
        const count = ref(0);
        dsHangMucTroCap.value.forEach(x => {
          if(x.tt > count.value) count.value = x.tt;
        })
        hangMucTroCap.value.tt = count.value + 1;
      }
      if(hangMucTroCap.value.khac_tc == null) hangMucTroCap.value.khac_tc = 100;
      if(hangMucTroCap.value.sd_tc == null) hangMucTroCap.value.sd_tc = 100;

      hangMucTroCap.value.tongTien = hangMucTroCap.value.sl_tc * hangMucTroCap.value.gia_tc * (hangMucTroCap.value.sd_tc/100) * (hangMucTroCap.value.khac_tc/100);

      if((hangMucTroCap.value.tongTien > hangMucTroCap.value.gia_cao_nhat_tc)&&(hangMucTroCap.value.gia_cao_nhat_tc > 0)){
        hangMucTroCap.value.tongTien = hangMucTroCap.value.gia_cao_nhat_tc;
      }
      if((hangMucTroCap.value.tongTien < hangMucTroCap.value.gia_thap_nhat_tc)&&(hangMucTroCap.value.gia_thap_nhat_tc > 0)){
        hangMucTroCap.value.tongTien = hangMucTroCap.value.gia_thap_nhat_tc;
      }

      if(hangMucTroCap.value.tinh_theo_nha_o)
      {
        hangMucTroCap.value.tongTien = tongTienNhaO.value * hangMucTroCap.value.gia_tc;
      }

      dsHangMucTroCap.value.push(hangMucTroCap.value);
      dsHangMucTroCap.value.filter(x => {
        tongTienTroCap.value = tongTienTroCap.value + x.tongTien;
      })
      var ktTemp = ref({} as HangMucTroCap);
      hangMucTroCap.value = ktTemp.value;

      showAddTroCap.value = false;
      itemTroCaps.value = [];
      const index = ref(0);
      dsHangMucTroCap.value.forEach(x => {
        if(x.tt > index.value) index.value = x.tt
        itemTroCaps.value.push(({
          tt: x.tt, ma: x.ma_tc, ten: x.ten_tc, sl: x.sl_tc, khac: x.khac_tc, sd: x.sd_tc, tongTien: Number(x.tongTien), dvt: x.dvt_tc, dg: x.gia_tc
        }));
      })
      itemTroCaps.value.push(({tt: index.value + 1, tongTien: Number(0)}))
      initData();
    }

    const deleteTroCap = (id) => {
      // console.log("################################ deleteThuoc id: " + id);
      dsHangMucTroCap.value.splice(dsHangMucTroCap.value.findIndex(x => x.tt == id), 1);
      dsHangMucTroCap.value.forEach(x => {
        if(x.tt > id)
        {
          x.tt = x.tt - 1;
        }
      });
      itemTroCaps.value.splice(itemTroCaps.value.findIndex(x => x.tt == id), 1);
      itemTroCaps.value.forEach(x => {
        if(x.tt > id)
        {
          x.tt = x.tt - 1;
        }
      });
      itemChinhs.value = [];
      initData();
    }

    const selectTroCap = () => {
      if(checkQuyetDinh())
        showTroCap.value = true;
    }

    const onRowSelectCC = ($event) => {
      hangMucCayCoi.value.ten_cc = $event.data.ten_cay_coi;
      hangMucCayCoi.value.ma_cc = $event.data.ma_cay_coi;
      hangMucCayCoi.value.dongia_cc = $event.data.gia_den_bu;
      hangMucCayCoi.value.dvt_cc = $event.data.don_vi_tinh;

      clearFilter();
      showCayCoi.value = false;
    }

    const themCayCoi = () => {
      if(dsHangMucCayCoi.value.length < 1){
        hangMucCayCoi.value.tt = 1;
      }
      else{
        const count = ref(0);
        dsHangMucCayCoi.value.forEach(x => {
          if(x.tt > count.value) count.value = x.tt;
        })
        hangMucCayCoi.value.tt = count.value + 1;
      }
      if((hangMucCayCoi.value.khac_cc == null)&&(!hangMucCayCoi.value.apgia)) hangMucCayCoi.value.khac_cc = 100;
      if((hangMucCayCoi.value.sd_cc == null)&&(!hangMucCayCoi.value.apgia))  hangMucCayCoi.value.sd_cc = 100;

      hangMucCayCoi.value.tong_tien = hangMucCayCoi.value.soluong_cc * hangMucCayCoi.value.dongia_cc * (hangMucCayCoi.value.khac_cc/100) * (hangMucCayCoi.value.sd_cc/100);
      if(hangMucCayCoi.value.apgia){
        const nhaOThuHoi = ref(0);
        const kienTrucThuHoi = ref(0);
        itemsNhaO.value.filter(x => {
          if(x.thuhoi){
            nhaOThuHoi.value = nhaOThuHoi.value + x.soluong_no;
          }
        })
        dsHangMucKienTruc.value.filter(x => {
          if(x.thuhoi){
            kienTrucThuHoi.value = kienTrucThuHoi.value + x.sl_kt;
          }
        })
        hangMucCayCoi.value.tong_tien = (chiTietDuAn.value.dien_tich_thu_hoi - (nhaOThuHoi.value + kienTrucThuHoi.value))* (hangMucCayCoi.value.hanmuc_cc);
      }

      dsHangMucCayCoi.value.push(hangMucCayCoi.value);
      dsHangMucCayCoi.value.filter(x => {
        tongTienCayCoi.value = tongTienCayCoi.value + x.tong_tien;
      })
      var ktTemp = ref({} as HangMucCayCoi);
      hangMucCayCoi.value = ktTemp.value;

      showAddCayCoi.value = false;
      itemHoaMaus.value = [];
      const index = ref(0);
      dsHangMucCayCoi.value.forEach(x => {
        if(x.tt > index.value) index.value = x.tt
        itemHoaMaus.value.push(({
          tt: x.tt, ma: x.ma_cc, ten: x.ten_cc, sl: x.soluong_cc, khac: x.khac_cc, sd: x.sd_cc, tongTien: Number(x.tong_tien), dvt: x.dvt_cc, dg: x.dongia_cc
        }));
      })
      itemHoaMaus.value.push(({tt: index.value + 1, tongTien: Number(0)}))
      // console.log("################################ itemHoaMaus: " + JSON.stringify(itemHoaMaus, (_, v) => typeof v === 'Number' ? v.toString() : v));
      initData();
    }


    const deleteCayCoi = (id) => {
      // console.log("################################ deleteThuoc id: " + id);
      dsHangMucCayCoi.value.splice(dsHangMucCayCoi.value.findIndex(x => x.tt == id), 1);
      dsHangMucCayCoi.value.forEach(x => {
        if(x.tt > id)
        {
          x.tt = x.tt - 1;
        }
      });
      itemHoaMaus.value.splice(itemHoaMaus.value.findIndex(x => x.tt == id), 1);
      itemHoaMaus.value.forEach(x => {
        if(x.tt > id)
        {
          x.tt = x.tt - 1;
        }
      });
      initData();
    }

    const selectCayCoi = () => {
      if(checkQuyetDinh())
        showCayCoi.value = true;
    }

    const calculateTotal = (name) => {
      let total = 0;

      // if (customers.value) {
      //   for (let customer of customers.value) {
      //     if (customer.representative.name === name) {
      //       total++;
      //     }
      //   }
      // }
      return 0;
    }

    const showAddDat = ref(false);
    const showAddNhaO = ref(false);
    const showAddKienTruc = ref(false);
    const showAddChinhSach = ref(false);
    const showAddTroCap = ref(false);
    const showAddCayCoi = ref(false);
    const addData = (id) => {
      if(id == 1) showAddDat.value = true;
      if(id == 2) showAddNhaO.value = true;
      if(id == 3) showAddKienTruc.value = true;
      if(id == 4) showAddChinhSach.value = true;
      if(id == 5) showAddTroCap.value = true;
      if(id == 6) showAddCayCoi.value = true;
    }
    const customers = ref();
    const expandedRowGroups = ref();
    const onRowGroupExpand = (event) => {
      // toast.add({ severity: 'info', summary: 'Row Group Expanded', detail: 'Value: ' + event.data, life: 3000 });
    };
    const onRowGroupCollapse = (event) => {
      // toast.add({ severity: 'success', summary: 'Row Group Collapsed', detail: 'Value: ' + event.data, life: 3000 });
    };

    const tinhTongTienNhom = (id) => {
      var tinhTT = Number(0);
      itemChinhs.value.forEach( x => {
        if(x.soNhom == id){
          tinhTT = tinhTT + Number(x.tongTien);
        }
      })
      if(tinhTT == Number(0)) return '';
      // Use Intl.NumberFormat to format the number as currency
      const formatter = new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
        minimumFractionDigits: 0, // Set the number of decimal places you want
      });
      return formatter.format(tinhTT);
    }

    return {
      tinhTongTienNhom,
      customers,
      expandedRowGroups,
      onRowGroupExpand,
      onRowGroupCollapse,
      filters,
      clearFilter,
      initFilters,
      dsQuyetDinh,
      dsQuan,
      quyetDinhSelect,
      dsDat,
      dsNhaO,
      dsKienTruc,
      dsCayCoi,
      dsChinhSach,
      dsTroCap,
      qDinh,
      quanHuyen,
      hangMucDat,
      itemsNhaO,
      formatCurrency,
      hangMucChuanNhaO,
      chiTietDuAn,
      selectDistrict,
      dsPhuong,
      dsViTriDat,
      selectViTriDat,
      selectLoaiDat,
      dsViTriKhoangCach,
      selectViTriKhoangCachDat,
      dsViTriKhac,
      selectViTriKhac,
      tongTienDat,
      themDat,
      dsHangMucDat,
      tinhTongHop,
      tongTien,
      updateThuHoi,
      deleteDat,
      validDat,
      nhaO,
      dsChiTietNhaO,
      onRowSelect,
      selectNhaO,
      showNhaO,
      themNhaO,
      deleteNhaO,
      tongTienNhaO,
      kienTruc,
      hangKienTruc,
      dsHangMucKienTruc,
      onRowSelectKT,
      showKienTruc,
      themKienTruc,
      deleteKienTruc,
      selectKienTruc,
      tongTienKienTruc,
      checkValidDat,
      hangMucChinhSach,
      dsHangMucChinhSach,
      onRowSelectCS,
      showChinhSach,
      themChinhSach,
      deleteChinhSach,
      selectChinhSach,
      tongTienChinhSach,
      onRowSelectTC,
      showTroCap,
      themTroCap,
      deleteTroCap,
      selectTroCap,
      tongTienTroCap,
      hangMucTroCap,
      dsHangMucTroCap,
      hangMucCayCoi,
      dsHangMucCayCoi,
      onRowSelectCC,
      themCayCoi,
      deleteCayCoi,
      selectCayCoi,
      tongTienCayCoi,
      showCayCoi,
      initData,
      itemChinhs,
      calculateTotal,
      addData,
      showAddDat,
      showAddNhaO,
      showAddKienTruc,
      showAddChinhSach,
      showAddTroCap,
      showAddCayCoi,
      deleteItem
    }
  }
}
