
import {computed, ref} from 'vue'
import { useStore } from 'vuex'
import { useToast } from "primevue/usetoast";
import router from "@/router";

export default {
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const toast = useToast();
    const defaultMenu = [
    ];

    const adminMenu = ref([
      {
        label: 'Trang chủ',
        icon: 'pi pi-fw pi-home',
        url: "/home"
      },
    ])
        const userMenu = ref([
      {
        label: 'Trang chủ',
        icon: 'pi pi-fw pi-home',
        url: "/home"
      },
    ])
    const appMenu = computed(() => {
      console.log("###########@@@@@@@@@@ check menu store.state.permission: " + store.state.permission);
      console.log("###########@@@@@@@@@@ check Data store: " + JSON.stringify(store.state));
      // coSoInfo.value = store.state;
      if((store.state.permission == 'a0b7fcc0332dbbc0b77b05ea2b6b0ded')||(store.state.permission == 'c3284d0f94606de1fd2af172aba15bf3'))
      {
        console.log("###########@@@@@@@@@@ check menu admin: ");
        return adminMenu.value;
      }
      else if(store.state.permission == '0d8d5cd06832b29560745fe4e1b941cf')
      {
        console.log("###########@@@@@@@@@@ check menu user: ");
        return userMenu.value;
      }
      else {
        console.log("###########@@@@@@@@@@ check menu aaaaaa: ");
        return defaultMenu;
      }
    });
    const logout = () => {
      store.dispatch('clearToken');
      store.dispatch('clearPermission');
      store.dispatch('clearTenCoSo');
      store.dispatch('clearDiaChi');
      store.dispatch('clearSoDienThoai');
      store.dispatch('clearHoVaTen');
      router.push({
        name: 'login',
      });
      toast.add({
        severity:'success',
        summary: 'Đăng xuất',
        detail:'Đã xóa thông tin đăng nhập thành công',
        life: 1000
      });
    }

    return {
      appMenu,
      logout
    }
  }
}
