import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import ApGiaDenBu from "@/pages/ApGiaDenBu.vue";

const routes: Array<RouteRecordRaw> = [

  {
    path: '/apgiadenbu',
    name: 'apgiadenbu',
    component: ApGiaDenBu,
    props: true
  },
  // {
  //   path: '/statistical',
  //   name: 'statistical',
  //   component: Statistical,
  //   props: true
  // },
  // {
  //   path: '/showlistinfo/:id',
  //   name: 'showlistinfo',
  //   component: ShowListInfo,
  //   props: true
  // },
  // {
  //   path: '/guidonthuoc',
  //   name: 'guidonthuoc',
  //   component: GoiDonThuoc,
  //   props: true
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
