<template>
  <Toast />
  <div class="container-scroller">
    <!-- partial:partials/_navbar.html -->
    <ApGiaDenBu/>

    <!-- partial -->
    <div class="container-fluid page-body-wrapper">
      <!-- partial:partials/_sidebar.html -->

      <!-- partial -->
      <main class="main-panel">

        <!-- content-wrapper ends -->
        <!-- partial -->
      </main>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
</template>

<style lang="scss">
.layout-main {
  //padding: 10px 0 0 0;
  height: calc(100% - 70px);
  overflow-y: scroll;
  float: left;
  margin-left: 300px;
  width: calc(100% - 300px);
  font-size: 10px;
  z-index: 888;
}
.footer {
  //padding: 10px 0 0 0;
  margin-top: 10px;
  height: 70px;
  overflow-y: scroll;
  float: left;
  margin-left: 300px;
  alignment: center;
}
.layout-main-header{
  alignment: left;
}
//.footer {
//  height: 10%;
//  overflow-y: scroll;
//  float: bottom;
//  margin-left: clamp(200px, 20%, 300px);
//  width: 100%;
//}
.layout-sidebar {
  position: fixed;
  width: 300px;
  height: 100%;
  z-index: 999;
  overflow-y: auto;
  user-select: none;
  transition: transform;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
@media screen and (max-width: 700px) {
  .layout-main {
    margin-left: 100px;
    width: calc(100% - 100px);
    height: calc(100% - 40px);
  }
  .footer {
    height: 40px;
    margin-left: 100px;
    width: calc(100% - 100px);
  }
  .footerFirst {
    font-size: 9px;
  }
  .footerSecond {
    font-size: 10px;
  }
.layout-sidebar {
  width: 100px;
  }
}
@media screen and (min-width: 701px) {
  .layout-main {
    margin-left: 200px;
    width: calc(100% - 200px);
    height: calc(100% - 40px);
  }
  .footer {
    height: 40px;
    margin-left: 200px;
    width: calc(100% - 200px);
  }
  .footerFirst {
    font-size: 13px;
  }
  .footerSecond {
    font-size: 11px;
  }
.layout-sidebar {
  width: 200px;
}
}

@media screen and (max-height: 900px) and (max-width: 500px) {
  .layout-main {
    margin-left: 100px;
    width: calc(100% - 100px);
    height: calc(100% - 80px);
  }
  .footer {
    height: 80px;
    margin-left: 100px;
    width: calc(100% - 100px);
  }
  .footerFirst {
    font-size: 13px;
  }
  .footerSecond {
    font-size: 11px;
  }
  .layout-sidebar {
    width: 100px;
  }
}
</style>
<script>
import TopNavBar from "@/components/TopNavBar";
import ApGiaDenBu from "@/pages/ApGiaDenBu";
import {useStore} from "vuex";
import {computed} from "vue";
import { mount } from '@vue/test-utils';

export default {
  components: {TopNavBar,ApGiaDenBu},
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => !!store.state.token);
    const tenCoSo = computed(() => store.state.tenCoSo);
    const diaChi = computed(() => store.state.diaChi);
    const sdt = computed(() => store.state.soDienThoai);
    const hoVaTen = computed(() => store.state.hoVaTen);

    const convertString = (st) => {
     return  st.replace("<br/ >", "");
    };

    return {
      isLoggedIn,
      tenCoSo,
      diaChi,
      sdt,
      hoVaTen,
      convertString
    }
    
  },
  updated(){
    jQuery(".byhome").click(function(){
        jQuery(this).toggleClass( "hide-bar" );
        jQuery(".sidebars").toggleClass( "hide-bar" );
        jQuery("#main_page").toggleClass( "hide-bar" );
        jQuery("#footer").toggleClass( "hide-bar" );
      });
  }
}
</script>
