import Repository from './Repository'
import {ThongTinDatTheoMa} from "@/models/thongTinDatTheoMa";

const resource = ''

export default new class {
  getDsQuan(): Promise<any>{
    return Repository.get(`${resource}/Districts/quanDaNang`);
  }
  getDsPhuong(id: any): Promise<any>{
    return Repository.get(`${resource}/Wards/phuongcuaquan/${id}`);
  }
  getDmQuyetDinh(): Promise<any>{
    return Repository.get(`${resource}/DmQuyetDinhs`);
  }
  getDmDat(): Promise<any>{
    return Repository.get(`${resource}/DmDats`);
  }
  getHangMucNhaO(id: number): Promise<any>{
    return Repository.get(`${resource}/GiaDenBuHangMucNhaOs/theoQuyetDinh/${id}`);
  }
  getKienTruc(id: number): Promise<any>{
    return Repository.get(`${resource}/GiaDenBuVkts/getTheoQuyetDinh/${id}`);
  }
  getCayCoi(id: number): Promise<any>{
    return Repository.get(`${resource}/GiaDenBuCayCois/getTheoQd/${id}`);
  }
  getDenBuChinhSach(id: number): Promise<any>{
    return Repository.get(`${resource}/GiaDenBuChinhSaches/getDenBuTheoQD/${id}`);
  }
  getTroCap(id: number): Promise<any>{
    return Repository.get(`${resource}/GiaDenBuTroCaps/getDenBuTheoQD/${id}`);
  }
  getThongTinDat(thongTinDatTheoMa: ThongTinDatTheoMa): Promise<any>{
    return Repository.post(`${resource}/GiaDenBuViTris/getTheoQdvaMa`, thongTinDatTheoMa);
  }
  getViTriKhoangCachDat(thongTinDatTheoMa: ThongTinDatTheoMa): Promise<any>{
    return Repository.post(`${resource}/HeSoKhoangCaches/GetDenBuTheoQDVaMaDat`, thongTinDatTheoMa);
  }
  getViTriKhac(thongTinDatTheoMa: ThongTinDatTheoMa): Promise<any>{
    return Repository.post(`${resource}/HeSoKhacs/theomavaqd`, thongTinDatTheoMa);
  }
}
